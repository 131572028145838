import { Remote } from '~/services/Remote'

export
function ajax(url, options = null, callback = null) {
	if (typeof options === 'function') {
		callback = options

		options = null
	}

	if (typeof callback !== 'function') {
		return Remote.get().http(url, options ?? {})
	}

	return Remote.get().http(url, options ?? {}).then(v => callback(null, v), e => callback(e ?? 'rejected'))
}
