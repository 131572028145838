import { Component, Watch } from '~/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { ajax } from '~/services/ajax'

export
function FreeChargeFollowViewProvider(SuperComponent) {
  @Component()
  class FreeChargeFollowView extends SuperComponent {
    powerList = []
    operatingGroupNum = null

    get title() {
      const { type } = this.$route.query

      for (const item of this.powerList) {
        if (item.type === +type) {
          return item.name
        }
      }

      return null
    }

    mounted() {
      console.log('[features] mounted')
      this.loadPowerList()
      this.loadCouponList()
    }

    loadPowerList() {
      ajax(
        '/api/power_list_index',
        {
          method: 'GET',
          // body: { type: this.$route.query.type, },
        },
        (error, data) => {
          if (error) {
            this.$message({ message: '获取数据失败', type: 'error' })
          } else {
            console.log('[features] power_list', data)
            this.powerList = data
          }
        }
      )
    }

    loadCouponList() {
      console.log('[features] loadCouponList', this)
      ajax(
        '/api/get_coupon_list',
        {
          method: 'POST',
          body: { type: this.$route.query.type, },
        },
        (error, data) => {
          if (error) {
            this.$message({ message: '获取数据失败', type: 'error' })
          } else {
            console.log('[features] get_coupon_list', data)
            for (const item of data.list) {
              if (!item.count) {
                item.count = item.total
              }
              if (!item.gmt_time) {
                item.gmt_time = item.gmt_start
              }
              if (!item.gmt_validity_time) {
                item.gmt_validity_time = `${item.validity_days}d`
              }
            }

            data[1] = data.l_1
            data[2] = data.l_2
            data[3] = data.l_3
            data[4] = data.l_4
            this.coupon = data
          }
        }
      )
    }

    /** @override */
    showDialog(groupNum) {
      this.dialogFormVisible = true

      this.operatingGroupNum = groupNum

      switch (this.operatingGroupNum) {
        case 1:
        case 3:
          if (this.coupon.list) {
            // this.sendCouponList.data = this.coupon.list
            this.$set(this.sendCouponList, 'data', this.coupon.list)
          }
          break
        case 2:
        case 4:
          if (this.coupon.list_y) {
            // this.sendCouponList.data = this.coupon.list_y
            this.$set(this.sendCouponList, 'data', this.coupon.list_y)
          }
          break
      }
    }

    /** @override */
    handleSubmitCoupon() {
      this.dialogFormVisible = false
      const { operatingGroupNum, chooseCoupon } = this
      if (operatingGroupNum && chooseCoupon) {
        if (!this.coupon[operatingGroupNum].includes(chooseCoupon)) {
          this.coupon[operatingGroupNum].push(chooseCoupon)
        }
      }

      this.operatingGroupNum = null
      this.chooseCoupon = null
    }

    /** @override */
    handleDelete(index, coupon, groupNum) {
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(
        () => {
          if (groupNum) {
            const idx = this.coupon[groupNum].indexOf(coupon)
            if (idx !== -1) {
              this.coupon[groupNum].splice(idx, 1)
            }
          }
        },
        () => {},
      )
    }

    /** @override */
    handleSubmit() {
      ajax(
        '/api/coupon_save',
        {
          method: 'POST',
          body: {
            type: this.$route.query.type,
            page_package_coupon_id: this.coupon[1] ? this.coupon[1].map(c => c.id) : [],
            page_power_coupon_id: this.coupon[3] ? this.coupon[3].map(c => c.id) : [],
          }
        },
        (error, data) => {
          if (error) {
            this.$message({ message: '提交失败', type: 'error' })
          } else {
            this.$message({ message: '提交成功', type: 'success', onClose: () => {
              this.$router.push('/admin/free_charge')
            } })
          }
        }
      )
    }

    /** @override @stub */
    getCouponList(type = 1) {
      console.log('[features][stub] getCouponList')
    }

    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)

      if (this.title) {
        const { value: mainHeader } = root.query({ as: <mainheader />}).next()

        mainHeader?.replaceChildren?.(
          <span slot="header-title">{ this.title }</span>
        )
      }

      Array.from(root.query({ as: <el-button icon="plus" /> }), (couponAddButtonView, i) => {
        couponAddButtonView.attrs['data-type'] = i + 1
        couponAddButtonView.listeners.click = () => this.showDialog(i + 1)
      })

      Array.from(root.query({ as: <el-table />, parentAs: <el-form-item /> }), (tableView, i) => {
        if (tableView) {
          tableView.props.data = this.coupon?.[i + 1] ?? []
        }
      })

      Array.from(root.query({ as: <el-table-column prop="name" label="优惠券名称" /> }), (couponNameColumnView, i) => {
        if (couponNameColumnView) {
          couponNameColumnView.props.prop = 'alias'
        }
      })

      Array.from(root.query({ as: <el-table-column label="操作" /> }), (operationColumnView, i) => {
        if (operationColumnView) {
          operationColumnView.scopedSlots.default = scope => /*h =>*/ (
            <el-button size="small" type="danger" onClick={ () => this.handleDelete(scope.$index, scope.row, i + 1) }>删除</el-button>
          )
        }
      })

      return vnode
    }

  }


  return FreeChargeFollowView
}
