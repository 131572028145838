import { Component, Watch } from '~/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { format } from '~/commons/lang/dates'

export
function VoteCreateViewProvider(SuperComponent) {
  @Component()
  class VoteCreateView extends SuperComponent {
    created() {
      this.$set(this.form, 'show_type', 1)
      this.$set(this.form, 'qr_img', null)
      this.$set(this.form, 'is_center', 1)
    }

    /** @override */
    addVote(data) {
      return super.addVote({
        ...data,

        gmt_start: this.form.date[0] ? format(this.form.date[0]) : '',
        gmt_end:   this.form.date[1] ? format(this.form.date[1]) : '',

        show_type: this.form.show_type || 1,
        qr_img:    this.form.qr_img,
        is_center: this.form.is_center,
      })
    }

    handleUpload2(data) {
      const formData = new FormData()

      formData.append('image', data.file)

      this.$http
        .post('/api/upload_image', formData, { emulateJSON: true })
        .then(
          response => {
            this.form.qr_img = response.body.data.path
          },
          response => {
            this.$message.error(response.body.data.msg)
          }
        )
    }

    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)

      const { value: siblingView } = root.query({ as: <el-form-item label="问卷图片" /> }).next()

      siblingView?.after(
        <el-form-item label="提交问卷后" rules={ this.rules }>
          <el-radio-group value={ this.form.show_type } onInput={ e => (this.form.show_type = e) }>
            <el-radio name="vote_show_type" label={ 1 }>发券页面</el-radio>
            <el-radio name="vote_show_type" label={ 2 }>展示二维码领券</el-radio>
          </el-radio-group>
        </el-form-item>
      )

      if (this.form.show_type === 2) {
        siblingView?.nextElementSibling()?.after(
          <el-form-item label="展示二维码" rules={ this.rules }>
            <el-upload
              action="/api/upload_image"
              http-request={ this.handleUpload2.bind(this) }
              show-file-list={ false }
              before-upload={ this.beforeImageUpload.bind(this) }
            >
              {
                this.form.qr_img ?
                  <img src={ this.form.qr_img } class="ad_img1" /> :
                  <i class="el-icon-plus avatar-uploader-icon" />
              }
            </el-upload>
          </el-form-item>
          ,

          <el-form-item label="推荐到个人中心" rules={ this.rules }>
            <el-radio-group value={ this.form.is_center } onInput={ e => (this.form.is_center = e) }>
              <el-radio name="vote_is_center" label={ 0 }>否</el-radio>
              <el-radio name="vote_is_center" label={ 1 }>是</el-radio>
            </el-radio-group>
          </el-form-item>
        )
      }

      if (this.form.show_type === 2) {
        const { value: siblingView2 } = root.query({ as: <el-form-item label="发放优惠券" /> }).next()

        siblingView2?.remove()

        const { value: siblingView3 } = root.query({ as: <el-form-item label="发放延时券" /> }).next()

        siblingView3?.remove()
      }

      return vnode
    }
  }


  return VoteCreateView
}
