import { ReactiveComponent } from '~/commons/component/ReactiveComponent'
import { Component, Watch } from '~/commons/component/decorators'

import { ajax } from '~/services/ajax'

import './user.css'

@Component()
export
  class ComposePackageUserView extends ReactiveComponent {
  keyword = '' // 用户搜索
  order_no = '' //商户单号搜索

  queryPageIndex = 1  // 当前页码
  queryPageSize = 10 // 每页行数
  total // 总行数

  composeOrderBuyerData = []  //表格数据
  loading = false  //表格加载效果

  btnLoading = false //导出按钮 加载效果

  // --- 退款弹窗相关 ----
  showRefundDialog = false // 弹窗 显示/隐藏
  dialogLoading = false //弹窗 确认按钮 加载效果
  msg = '' //告诫文本
  pay_amount = null  //弹窗最大金额 对应列表接口返回的pay_amount字段
  refundData = { compose_order_id: null, amount: null, remark: null } // 退款参数


  //  --- 卡卷加减次数弹窗相关 ---
  showNumberDetailDialog = false // 卡卷加减次数弹窗 显示/隐藏
  numberDialogData = [] //弹窗显示内容
  numArguments = { id: null , operator :null, type:null,order_id :null } //卡卷 加减次数接口参数


  created() {
    this.getComposeOrderBuyerList()
  }

  @Watch('keyword')
  @Watch('order_no')
  @Watch('queryPageIndex')
  @Watch('queryPageSize')
  loadComposeOrderBuyerList() {
    this.getComposeOrderBuyerList()
  }


  getComposeOrderBuyerList() {
    this.loading = true
    ajax(`/api/packageComposeOrder/buyerList?package_compose_id=${this.$route.query.id}&page_size=${this.queryPageSize}&keyword=${this.keyword}&order_no=${this.order_no}&page=${this.queryPageIndex}&is_export=0`,
      {
        method: 'GET',
      },

      (error, data) => {
        if (error) {
          this.$message({ message: '获取数据失败', type: 'error' })
        } else {
          this.composeOrderBuyerData = data.list
          this.total = data.total

          this.loading = false
        }
      })
  }

  // 关键字搜索框 节流
  handleInput(type,e) {
    clearTimeout(this.debounceTimer)

    this.debounceTimer = setTimeout(() => {
      if(type === 'keyword'){
        this.keyword = e
      }else {
        this.order_no = e
      }

    }, 500)
  }

  // 导出
  handleExport() {
    this.btnLoading = true
    ajax(`/api/packageComposeOrder/buyerList?package_compose_id=${this.$route.query.id}&page_size=${this.queryPageSize}&keyword=${this.keyword}&page=${this.queryPageIndex}&is_export=1`,
      {
        method: 'GET',
      },

      (error, data) => {
        if (error) {
          this.$message({ message: '获取数据失败', type: 'error' })
        } else {
          window.location.href = data.url

          this.btnLoading = false
        }
      })
  }

  // 禁用
  banOrder(scope) {
    this.$confirm(`是否禁用 ${scope.row.nickname} 用户?`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      ajax('/api/packageComposeOrder/ban',
        {
          method: 'POST',
          body: {
            compose_order_id: scope.row.id
          }
        },

        (error, data) => {
          // 刷新列表
          this.getComposeOrderBuyerList()

          this.$message({ type: 'success', message: '操作成功' });
        })
    })

  }

  // 1.退/用卷按钮 2.退款按钮 disabled
  disabledNumDialogOrRefund(row){
    return row.order_status === 3 ? true : false
  }

  //  --- 退款弹窗相关 ---
  // 弹出弹窗
  showDialog(scope) {
    this.msg = '' //告诫文本清空
    this.dialogLoading = false //关闭弹窗确定加载效果

    this.pay_amount = Number(scope.row.pay_amount) //记录实付金额

    ajax(`/api/packageComposeOrder/getRefundAmount?compose_order_id=${scope.row.id}`,
      {
        method: 'GET'
      },

      (error, data) => {
        if (error) {
          this.$message({ message: error.msg ?? '操作失败', type: 'error' })
        } else {
          // 数据清空
          this.refundData = {
            compose_order_id: scope.row.id,   //记录id
            amount: data.refund_amount,  // 记录退款金额
            remark: null
          }

          this.showRefundDialog = true
        }
      })


  }

  // 弹窗确定逻辑
  refundBtnOk() {
    if (!this.refundData.amount || !this.refundData.remark) {
      this.msg = '请填写退款金额和备注'
      this.is_caution = true
      return
    }

    if (this.refundData.amount > this.pay_amount) {
      this.msg = '退款金额不得超过最大退款金额'
      this.is_caution = true
      return
    }

    this.dialogLoading = true

    ajax('/api/packageComposeOrder/refund',
      {
        method: 'POST',
        body: {
          ...this.refundData
        }
      },

      (error, data) => {
        if (error) {
          this.$message({ message: error.msg ?? '操作失败', type: 'error' })
        } else {
          this.$message.success('操作成功')

          this.getComposeOrderBuyerList() //刷新列表

          this.dialogLoading = false
          this.showRefundDialog = false
        }
      })


  }


  //  --- 卡卷弹窗相关 ---
  // 弹出 卡卷加减次数弹窗
  addOrSubNumberDetail(id,type,operator,order_id) {
    this.numArguments.operator = operator // 记录 'add' or 'sub'
    this.numArguments.type = type // 记录 走套餐卡加减逻辑 还是 延时卷加减逻辑
     if(type === 'delay_coupon'){
      this.numArguments.order_id = order_id //点击延时卷 退/用卷 记录 当前order_id
     }
      ajax(`/api/packageComposeOrder/getNumberDetail?compose_order_id=${id}&type=${type}`,
      {
        method:'GET'
      },

      (error,data) =>{
        if(error){
          this.$message({message:error.body.msg ?? '获取数据失败',type:'error'})
        } else {
          this.numberDialogData = data.user_package_cards ?? data.user_delay_coupons // user_package_cards : 套餐卡  user_delay_coupons ：延时卷
          this.numberDialogData.forEach((item,index) => { this.$set(item,'check',0) }) //记录多选框选中状态 默认未选中0
          this.showNumberDetailDialog = true  //弹出弹窗
        }
      })


  }

  // 点击 卡卷加减次数弹窗 多选框逻辑
  changeCheck(e, item) {
    item.check = e
    // 排他 达到单选效果
    this.numberDialogData.forEach((v) => {
      v.check = v.id === item.id ? 1 : 0
    })
    console.log('this.numberDialogData',this.numberDialogData);
  }

  // 多选框 是否禁用
  disabledOrNot(item){
    // 套餐卡
    if(this.numArguments.type === 'package_card'){
      // 'add'(退卷)：1.套餐卡 使用次数 为0 - 不可以退卷
      if(this.numArguments.operator === 'add'){
        return item.consume_num === 0 ? true : false
      }
      // 'sub'(用卷)：1.套餐卡 使用次数 大于等于 总次数 - 不可以用卷
      if(this.numArguments.operator === 'sub'){
        return item.consume_num >= item.package_num ? true : false
      }
    }
   // 延时卷
    if(this.numArguments.type === 'delay_coupon'){
     // 'add'(退卷)：1. 延时卷已使用(is_use = 1) - 可以退卷  2.延时卷未使用(is_use = 2) - 不可以退卷
      if(this.numArguments.operator === 'add'){
        return item.is_use === 2 ? true : false
       }
      //  'sub'(用卷)：1.延时卷已使用(is_use = 1) - 不可以用卷 2.延时卷未使用(is_use = 2) - 可以用卷
       if(this.numArguments.operator === 'sub'){
         return item.is_use === 1 ? true : false
       }
    }

  }

  // 点击 卡卷加减次数弹窗 确认
  handleSubmit() {
    // 记录 选中 套餐卡/延时卷 id
    this.numberDialogData.forEach(item => {
      if(item.check === 1){
        this.numArguments.id = item.id
      }
    })
    // 套餐卡逻辑
    if(this.numArguments.type === 'package_card'){
      ajax('/api/packageComposeOrder/updatePackageCardNum',
      {
        method: 'POST',
        body: {
          operator:this.numArguments.operator, // 'add' or 'sub'
          user_card_id : this.numArguments.id //选中套餐卡id
        }
      },

      (error, data) => {
        if (error) {
          this.$message({ message: error.message ?? '获取数据失败', type: 'error' })
        } else {
          this.$message({ message:'操作成功',type:'success' })
          this.getComposeOrderBuyerList() // 刷新列表
          this.showNumberDetailDialog = false // 关闭卡卷加减次数弹窗
        }
      })
    }
    // 延时卷逻辑
    if(this.numArguments.type === 'delay_coupon'){
      ajax('/api/packageComposeOrder/updateDelayCouponNum',
      {
        method:'POST',
        body:{
          operator:this.numArguments.operator, // 'add' or 'sub'
          delay_user_id:this.numArguments.id,  //选中延时卷id
          order_id : this.numArguments.order_id // 当前订单id
        }
      },

      (error,data) => {
        if(error){
          this.$message({ message: error.message ?? '获取数据失败', type: 'error' })
        } else {
          this.$message({ message:'操作成功',type:'success' })
          this.getComposeOrderBuyerList() // 刷新列表
          this.showNumberDetailDialog = false // 关闭卡卷加减次数弹窗
        }
      })
    }

  }

  // 关闭 卡卷加减次数弹窗
  closeNumberDialog(){
    this.showNumberDetailDialog = false

    this.numArguments = { id: null, operator : null,type : null}
    this.numberDialogData = []
  }


  render() {
    return (
      <section class='package-user'>
        {/* search-box */}
        <section style="margin-top: 20px;">
          <el-row gutter={24}>
            <el-col span={5}>
              <el-input placeholder="用户openid搜索" value={this.keyword} onInput={e => this.handleInput('keyword',e)} suffix-icon="el-icon-search" clearable />
            </el-col>

            <el-col span={5}>
              <el-input placeholder="商户单号搜索" value={this.order_no} onInput={e => this.handleInput('order_no',e)} suffix-icon="el-icon-search" clearable />
            </el-col>

            <el-col span={14} style="text-align: right;">
              <el-button type="primary" onClick={() => this.handleExport()} v-loading={this.btnLoading}>导出</el-button>
            </el-col>
          </el-row>
        </section>

        {/* content */}
        <section style="margin-top: 20px;">
          <el-table data={this.composeOrderBuyerData} border style="width: 100%" v-loading={this.loading}>
            <el-table-column prop="id" label="id" width="100"/>
            <el-table-column prop="openid" label="用户名" />
            <el-table-column prop="order.order_no" label="商户单号" />
            <el-table-column prop="gmt_pay" label="购买时间" />
            <el-table-column label="套餐次数/使用次数" width="250" scopedSlots={
              {
                default: scope => (
                  <el-row>
                    <el-col span={ 10 }>
                      <span>{scope.row.package_num} / {scope.row.use_package_num} </span>
                    </el-col>
                    <el-col span={ 14 }>
                      <el-button type="primary" size='mini' onClick={() => this.addOrSubNumberDetail(scope.row.id, 'package_card','add')} disabled={this.disabledNumDialogOrRefund(scope.row)}>退次数</el-button>
                      <el-button type="danger" size='mini' onClick={() => this.addOrSubNumberDetail(scope.row.id, 'package_card','sub')} disabled={this.disabledNumDialogOrRefund(scope.row)}>用次数</el-button>
                    </el-col>
                  </el-row>
                )
              }
            } />
            <el-table-column label="延时卷数/使用次数" width="250"  scopedSlots={
              {
                default: scope => (
                  <el-row>
                    <el-col span={ 10 }>
                      <span>{scope.row.delay_coupon_num} / {scope.row.use_delay_coupon_num}</span>
                    </el-col>
                    <el-col span={ 14 }>
                      <el-button type="primary" size='mini' onClick={() => this.addOrSubNumberDetail(scope.row.id, 'delay_coupon','add',scope.row.order_id)} disabled={this.disabledNumDialogOrRefund(scope.row)}>退次数</el-button>
                      <el-button type="danger" size='mini' onClick={() => this.addOrSubNumberDetail(scope.row.id, 'delay_coupon','sub',scope.row.order_id)} disabled={this.disabledNumDialogOrRefund(scope.row)}>用次数</el-button>
                    </el-col>
                  </el-row>
                )
              }
            } />
            <el-table-column label="优惠卷数/使用次数" scopedSlots={
              {
                default: scope => (
                  <span>{scope.row.coupon_num} / {scope.row.use_coupon_num} </span>
                )
              }
            } />
            <el-table-column label="操作" scopedSlots={
              {
                default: scope => (
                  <span>
                    <el-button size="small" onClick={() => this.showDialog(scope)} disabled={this.disabledNumDialogOrRefund(scope.row)}>退款</el-button>
                    <el-button type="warning" size="small" onClick={() => this.banOrder(scope)} disabled={scope.row.is_ban === 1 ? true : false}>禁用</el-button>
                  </span>
                )
              }
            } />
          </el-table>
        </section>

        {/* pagination */}
        <section style="margin-top: 20px; ">
          <el-row>
            <el-col span={12}>
              <el-pagination
                currentPage={this.queryPageIndex}
                pageSize={this.queryPageSize}
                page-sizes={[10, 20, 30, 40]}
                layout="total, sizes,->,prev, pager, next"
                on-size-change={e => (this.queryPageSize = e)}
                on-current-change={e => (this.queryPageIndex = e)}
                total={this.total} />
            </el-col>
          </el-row>

        </section>

        <el-dialog
          title="提示"
          visible={this.showRefundDialog}
          before-close={() => this.showRefundDialog = false}>
          <el-form value={this.refundData} onInput={(e) => this.refundData = e}>
            <el-row>
              <el-col span={12}>
                <el-form-item label='退款金额 :'>
                  <el-input value={this.refundData.amount} onInput={(e) => this.refundData.amount = Number(e)} style="width: 70%;">
                    <span slot="append">元</span>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col span={12}>
                <i class='el-icon-warning' /><span>最大退款金额为{this.pay_amount}</span>
              </el-col>
            </el-row>


            <el-form-item label='退款备注 :'>
              <el-input type="textarea" value={this.refundData.remark} onInput={(e) => this.refundData.remark = e} style="width: 70%;" />
              {
                this.msg &&
                <span>
                  <i class="my-weibiaoti1"></i><span class="colorF37800">{this.msg}</span>
                </span>
              }

            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button onClick={() => this.showRefundDialog = false}>取 消</el-button>
            <el-button type="primary" onClick={() => this.refundBtnOk()} v-loading={this.dialogLoading}>确 定</el-button>
          </span >
        </el-dialog>

        <el-dialog
          title='次数提示'
          visible={this.showNumberDetailDialog}
          before-close={() => this.closeNumberDialog()}>
          <el-form >
            <el-row>
              <el-col span={18}>
                {
                  this.numberDialogData?.map((item, index) => (
                    <el-form-item>
                        <el-card>
                          <el-checkbox value={item.check} onInput={(e) => this.changeCheck(e, item)} true-label={1} false-label={0} disabled={this.disabledOrNot(item)}>{item.package_card_name ?? item.alias}</el-checkbox>
                          {
                            this.numArguments.type === 'package_card' ?
                            <span style='float:right'>(次数：{item.package_num} / 已使用次数：{item.consume_num})</span> :
                            <span style='float:right'>{item.is_use === 1 ? '已使用': '未使用'}</span>
                          }
                        </el-card>
                    </el-form-item>
                  ))
                }
              </el-col>
            </el-row>
          </el-form>

          <span slot="footer" class="dialog-footer">
            <el-button onClick={() => this.closeNumberDialog()}>取 消</el-button>
            <el-button type="primary" onClick={() => this.handleSubmit()}>确 定</el-button>
          </span >
        </el-dialog>
      </section>
    )
  }
}
