import { Component, Watch } from '~/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { ajax } from '~/services/ajax'

import './edit.css'

export
function SupervisorEditViewProvider(SuperComponent) {
  @Component()
  class SupervisorEditView extends SuperComponent {
    packageTableData = []

    mounted() {
      this.loadPackageData()
    }

    loadPackageData() {
      ajax(
        '/api/stage_package_list',
        {
          method: 'POST',
          body: {
            supervisor_id: this.$route.params.id,
          },
        },
        (error, data) => {
          if (error) {
            this.$message({ message: '获取数据失败', type: 'error' })
          } else {
            this.packageTableData = data.data
          }
        }
      )
    }

    postPackageItem() {
      const item = {
        id: 0,
        supervisor_id: this.$route.params.id,
        title: '',
        stage_time: '',
        month_title: '',
        month_price: '',
        quarter_title: '',
        quarter_price: '',
        half_year_title: '',
        half_year_price: '',
        over_title: '',
      }

      this.promptPackageItem(item, () => {
        ajax(
          '/api/stage_package_add',
          {
            method: 'POST',
            body: item,
          },

          error => {
            if (error) {
              this.$message({ message: '操作失败', type: 'error' })
            } else {
              this.$message({ message: '操作成功', type: 'success' })

              this.loadPackageData()
            }
          }
        )
      })
    }

    putPackageItem(item) {
      this.promptPackageItem(item, () => {
        ajax(
          '/api/stage_package_save',
          {
            method: 'POST',
            body: item,
          },

          error => {
            if (error) {
              this.$message({ message: '操作失败', type: 'error' })
            } else {
              this.$message({ message: '操作成功', type: 'success' })

              this.loadPackageData()
            }
          }
        )
      })
    }

    promptPackageItem(item, callback) {
      const formData = {}

      this.$msgbox({
        message: (
          <section>
            <el-form label-position="left" label-width="100px" model={ formData } onInput={ e => (formData = e) }>
              <el-form-item label="分期名称">
                <el-input placeholder="请输入分期名称" value={ item.title } onInput={ e => (item.title = e) } />
              </el-form-item>
              <el-form-item label="分期数量">
                <el-input placeholder="请输入分期数量" value={ item.stage_time } onInput={ e => (item.stage_time = Number(e)) } />
              </el-form-item>
              <el-form-item label="月套餐名称">
                <el-input placeholder="请输入月套餐名称" value={ item.month_title } onInput={ e => (item.month_title = e) } />
              </el-form-item>
              <el-form-item label="月套餐金额">
                <el-input placeholder="请输入月套餐金额" value={ item.month_price } onInput={ e => (item.month_price = Number(e)) } />
              </el-form-item>
              <el-form-item label="季度餐名称">
                <el-input placeholder="请输入季度餐名称" value={ item.quarter_title } onInput={ e => (item.quarter_title = e) } />
              </el-form-item>
              <el-form-item label="季度餐金额">
                <el-input placeholder="请输入季度餐金额" value={ item.quarter_price } onInput={ e => (item.quarter_price = Number(e)) } />
              </el-form-item>
              <el-form-item label="半年餐名称">
                <el-input placeholder="请输入半年餐名称" value={ item.half_year_title } onInput={ e => (item.half_year_title = e) } />
              </el-form-item>
              <el-form-item label="半年餐金额">
                <el-input placeholder="请输入半年餐金额" value={ item.half_year_price } onInput={ e => (item.half_year_price = Number(e)) } />
              </el-form-item>
              <el-form-item label="买断餐名称">
                <el-input placeholder="请输入买断餐名称" value={ item.over_title } onInput={ e => (item.over_title = e) } />
              </el-form-item>
            </el-form>
          </section>
        ),

        showCancelButton: true,
        showConfirmButton: true,

        cancelButtonText: '放弃',
        confirmButtonText: '提交',

        beforeClose:(action,instance,done) => {
          if(action == 'confirm' && Object.values(item).some(value => value === '')){
              this.$message({
                message: '请填写所有选项',
                type: 'warning'
              })
          }else{
            done()
          }
        },

        closeOnClickModal: false,
        closeOnPressEscape: false,

        customClass: 'supervisor-edit-prompt',
      })
      .then(
        callback,

        // no op
        () => {}
      )
    }

    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)

      root.classList.add('supervisor-edit-main')

      const { value: tabs } = root.query({ as: <el-tabs /> }).next()

      if (tabs) {
        tabs.children.push(
          <el-tab-pane label="分期套餐" name="fifth" />
        )
      }

      if (this.activeName === 'fifth') {
        root.children.push(
          <div>
              <el-card shadow="never" border="never">
                <el-row slot="header">
                  <el-col span={ 4 } offset={ 24 - 4 } style={ { textAlign: 'right' } }>
                    <el-button type="primary" onClick={ () => this.postPackageItem() }>添加套餐</el-button>
                  </el-col>
                </el-row>

                <el-table data={ this.packageTableData } stripe cell-style={ { textAlign: 'center' } } >
                  <el-table-column label="分期名称" prop="title" />
                  <el-table-column label="分期数量" prop="stage_time" />
                  <el-table-column label="月套餐" scopedSlots={
                    {
                      default: scope => (
                        <span>{ scope.row.month_title } ({ scope.row.month_price }元)</span>
                      )
                    }
                  } />
                  <el-table-column label="季度套餐" scopedSlots={
                    {
                      default: scope => (
                        <span>{ scope.row.quarter_title } ({ scope.row.quarter_price }元)</span>
                      )
                    }
                  } />
                  <el-table-column label="半年套餐" scopedSlots={
                    {
                      default: scope => (
                        <span>{ scope.row.half_year_title } ({ scope.row.half_year_price }元)</span>
                      )
                    }
                  } />
                  <el-table-column label="买断套餐" prop="over_title" />
                  <el-table-column label="操作" scopedSlots={
                    {
                      default: scope => (
                        <span>
                          <el-button type="primary" size="small" plain onClick={ () => this.putPackageItem(scope.row) }>编辑</el-button>
                        </span>
                      )
                    }
                  } />
                </el-table>
              </el-card>
          </div>
        )
      }

      return vnode
    }

  }


  return SupervisorEditView
}
