import { Component, Watch } from '~/commons/component/decorators'

export
function DetectCreateViewProvider(SuperComponent) {
  @Component()
  class DetectCreateView extends SuperComponent {
    /** @override */
    beforeImageUpload(file) {
      if (!/^image\//.test(file.type)) {
        this.$message.error(`上传图片格式非法!`)

        return false
      }

      const MAX_SIZE = 2 * (1 << 20)

      if (file.size > MAX_SIZE) {
        this.$message.error("上传图片大小不能超过 2MB!")

        return false
      }

      return true
    }
  }


  return DetectCreateView
}
