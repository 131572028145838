import { ReactiveComponent } from '~/commons/component/ReactiveComponent'
import { Component, Watch } from '~/commons/component/decorators'

import { ajax } from '~/services/ajax'

import './index.css'

@Component()
export
  class ComposePackageIndexView extends ReactiveComponent {
  keyWord = '' // 关键字搜索
  debounceTimer = null //搜索框防抖定时器
  status = '' // 活动状态
  stateOptions = [{ name: '启动', id: 1 }, { name: '禁用', id: 2 }]
  packageComposeData = []
  loading = false //加载效果

  queryPageIndex = 1  // 分页页码
  queryPageSize = 10 // 每页行数
  total // 总条数

  saleTypeFormatter = {
    1 : '销售',
    2 : '兑换'
  }

  saleChannelFormatter = {
    1 : '对内销售',
    2 : '对外销售'
  }

  statusFormatter = {
    1 : '启用',
    2 : '禁用'
  }


  created(){
    this.getPackageComposeList()
  }

  @Watch('queryPageIndex')
  @Watch('queryPageSize')
  @Watch('keyWord')
  @Watch('status')
   loadPackageComposeList(){
     this.getPackageComposeList()
   }


  getPackageComposeList(){
    this.loading = true
    ajax(`/api/packageCompose/index?page=${this.queryPageIndex}&keyword=${this.keyWord}&status=${this.status}&page_size=${this.queryPageSize}`,
    {
      method:'GET',
    },

    (error,data) => {
      if (error) {
        this.$message({ message: '获取数据失败', type: 'error' })
      } else {
        this.packageComposeData = data.list.data
        this.packageComposeData.map((item) =>{item.link="https://" + document.domain +`/home/composePackageDetail?compose_id=${item.id}`})
        this.total = data.list.total
        this.loading = false
      }
    }
    )
  }

  // 关键字搜索框 节流
  handleInput(e){
    clearTimeout(this.debounceTimer)

    this.debounceTimer = setTimeout(() => {
      this.keyWord = e
    },500)
  }

  // 复制链接
  async copyLink(row){
    if(row.sale_type === 2){
      // 若销售方式为 2 ：兑换 -> 生成兑换码链接
      await navigator.clipboard.writeText('https://' + document.domain + '/home/couponConversion?compose_code=true')
    }else{
       // 若销售方式为 1 ：销售 -> 生成套餐链接
      await navigator.clipboard.writeText(row.link)
    }

    this.$message({ message: '复制成功', type: 'success' })
  }

  // 禁用 逻辑
  banpackageCompose(scope){
    this.$confirm(`是否禁用 ${scope.row.name} 套餐?`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      ajax(`/api/packageCompose/ban/${scope.row.id}`,
      {
        method:'POST'
      },

      (error,data) => {
        if (error) {
          this.$message({ message: error.message ?? '操作失败', type: 'error' })
        } else {
          // 刷新列表
          this.getPackageComposeList()
          this.$message({type: 'success',message: '操作成功'});
        }
      }
      )

    })
  }



  render() {
    return (
      <section class='compose-package'>
        {/* search-box */}
        <section style="margin-top: 20px;">
          <el-row gutter={24}>
            <el-col span={6}>
              <el-input placeholder="关键字搜索" value={this.keyWord} onInput={e => this.handleInput(e)} suffix-icon="el-icon-search" clearable />
            </el-col>
            <el-col span={6}>
              <el-select placeholder="活动状态" value={this.status} onInput={e => (this.status = e)} filterable clearable>
                {
                  this.stateOptions.map(item => (
                    <el-option label={item.name} value={item.id} />
                  ))
                }
              </el-select>
            </el-col>

            <el-col span={ 12 } style="text-align: right;">
            <router-link to={ { name: 'compose_package.add_edit' } }>
              <el-button type="primary">新建</el-button>
            </router-link>
            </el-col>
          </el-row>
        </section>

        {/* content */}
        <section style="margin-top: 20px;">
          <el-table data={this.packageComposeData} border style="width: 100%" v-loading={ this.loading }>
            <el-table-column prop="name" label="组合套餐名称" />
            <el-table-column prop="alias_name" label="组合套餐别名" />
            <el-table-column prop="sale_type" label="销售方式" formatter={ (_,__,value) => this.saleTypeFormatter[value] } />
            <el-table-column prop="sale_channel" label="销售渠道" formatter={ (_,__,value) => this.saleChannelFormatter[value] }  />
            <el-table-column prop="order_quantity" label="售出/兑换数量" scopedSlots={
              {
                default: scope => (
                   <span>
                     <span>{scope.row.order_quantity}</span>
                     <router-link to={ { name: 'compose_package.exchange', query: { id: scope.row.id } } }>
                      {
                       scope.row.sale_type === 2 &&
                        <el-button type="text" style="margin-left: 10px;"><i class="my-fasong"></i>派发</el-button>
                      }
                     </router-link>
                   </span>
                )
              }
            } />
            <el-table-column prop="begin_time" label="活动开始时间" width="200"/>
            <el-table-column prop="end_time" label="活动结束时间" width="200"/>
            <el-table-column prop="status" label="状态" formatter={ (_,__,value) => this.statusFormatter[value]}/>
            <el-table-column label="操作" width="400" scopedSlots={
              {
                default: scope => (
                <span>
                  <el-row>
                    <el-col span={ 4 }>
                     <router-link to={ { name: 'compose_package.add_edit', query: { id: scope.row.id } } }>
                      <el-button  size="small">查看</el-button>
                     </router-link>
                    </el-col>
                    <el-col span={ 7 }>
                     <router-link to={ { name: 'compose_package.user', query: { id: scope.row.id } } }>
                      <el-button type="primary" size="small">已购买用户</el-button>
                     </router-link>
                    </el-col>
                    <el-col span={ 7 }>
                      <el-button type="primary" size="small" onClick={() => this.copyLink(scope.row)}>{scope.row.sale_type === 2 ? '复制兑换码链接':'复制套餐链接'}</el-button>
                    </el-col>
                    <el-col span={ 5 }>
                     <el-button type="warning" size="small"  onClick={ () => this.banpackageCompose(scope) } disabled={scope.row.status === 2 ? true : false}>禁用</el-button>
                    </el-col>
                  </el-row>
                </span>
                )
              }
            } />
          </el-table>
        </section>

        {/* pagination */}
        <section style="margin-top: 20px; ">
          <el-row>
            <el-col >
              <el-pagination
                currentPage={this.queryPageIndex}
                pageSize={this.queryPageSize}
                page-sizes={[10, 20, 30, 40]}
                layout="total, sizes,prev, pager, next"
                on-size-change={e => (this.queryPageSize = e)}
                on-current-change={e => (this.queryPageIndex = e)}
                total={this.total} />
            </el-col>
          </el-row>
        </section>
      </section>
    )
  }
}
