import { Component, Watch } from '~/commons/component/decorators'


import categories from '~/pages/supervisor/categories.json'

export
function SupervisorIndexViewProvider(SuperComponent) {
  @Component()
  class SupervisorIndexView extends SuperComponent {
    get defaultSupervisorType() {
      return categories[0].label
    }

    /** @override */
    initSearch() {
      super.initSearch()

      this.search_type = categories
    }

    /** @override */
    getSupervisorType(item) {
      for (const option of this.search_type) {
        if (option.value === item.type) {
          return option.label
        }
      }

      return this.defaultSupervisorType
    }
  }


  return SupervisorIndexView
}
