import { Component } from '~/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

export
function EditDialogProvider(SuperComponent) {
  @Component()
  class EditDialog extends SuperComponent {
    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)

      const { value: siblingView } = root.query({ as: <el-input placeholder="备注" />, parentAs: <el-card class="box-card" /> }).next()

      siblingView?.before(
        <el-input value={ this.dialog.erp_code } onInput={ e => (this.dialog.erp_code = e) }>
         <template slot="prepend">ERP编码</template>
        </el-input>
      )

      siblingView?.before(
        <el-input value={ this.dialog.tax } onInput={ e => (this.dialog.tax = e) }>
         <template slot="prepend">税费</template>
        </el-input>
      )

      return vnode
    }
  }

  return EditDialog
}
