import { Component, Watch } from '~/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { ajax } from '~/services/ajax'

import { format } from '~/commons/lang/dates'

export
function VoteIndexViewProvider(SuperComponent) {
  @Component()
  class VoteIndexView extends SuperComponent {
    /** @override */
    updateVote(data) {
      return new Promise((resolve, reject) => {
        ajax(
          `/api/vote/${data.id}`,
          {
            method: 'PUT',
            body: data,
          },

          err => {
            if (err) {
              this.$message.error(err.message ?? String(err))
              reject(err)
            } else {
              this.$message.success('操作成功')
              resolve()
            }
          }
        )
      })
    }

    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)

      const { value: operationColumnView } = root.query({ as: <el-table-column label="操作" /> }).next()

      if (operationColumnView) {
        const renderScopedSlot = operationColumnView.scopedSlots.default

        operationColumnView.scopedSlots.default = scope => {
          const children = renderScopedSlot.call(this, scope)

          children.unshift(
            <router-link
              tag="el-button"
              class="el-button--primary el-button--small"
              to={{
                name: 'vote.show',
                params: { id: scope.row.id, data: scope.row }
              }}
            >查看</router-link>
          )

          return children
        }
      }

      return vnode
    }
  }


  return VoteIndexView
}
