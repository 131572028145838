import { Component, Watch } from '~/commons/component/decorators'

import discount from './discount.json'

export
function DiscountCreateViewProvider(SuperComponent) {
  @Component()
  class DiscountCreateView extends SuperComponent {
    typeList = discount.types

    @Watch('discount.type')
    handleDiscountTypeChange(_, prev) {
      if (this.discount.type === 5) {
        this.isOnSchedule = true
        this.discount.week = []
      }
    }
  }


  return DiscountCreateView
}
