export function formatDate(date) {
  return date.toLocaleDateString('sv-SE')
}

export function formatTime(date) {
  return date.toLocaleTimeString('sv-SE')
}

export function format(date) {
  return date.toLocaleString('sv-SE')
}

export
function lastDate(date) {
  date = new Date(date)

  date.setDate(date.getDate() - 1)

  return date
}

export
function nextDate(date) {
  date = new Date(date)

  date.setDate(date.getDate() + 1)

  return date
}

export
function lastDateOfMonth(date) {
  date = new Date(date)

  date.setMonth(date.getMonth() + 1)
  date.setDate(date.getDate() - 1)

  return date
}
