import { Component, Watch } from '~/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'


export
  function DeviceNewViewProvider(SuperComponent) {
  @Component()
  class DeviceNewView extends SuperComponent {
    showDeleteDialog = false //弹窗 显示/隐藏
    deleteLoading = false  // 弹窗 删除按钮加载效果
    errText = '' // 弹窗错误文本

    fileName = ''
    file = null

    isShowDeleteDialog(){
      this.file = null
      this.fileName = ''
      this.errText = ''
      this.deleteLoading = false
      this.showDeleteDialog = true
    }

    getFile(e) {
      this.file = e.target.files[0];
      this.fileName = e.target.files[0].name;
    }

    downLoad(){
    //  window.location.href = '/batchDeleteTemplate'
      this.$http.get('/batchDeleteTemplate',{responseType:"blob"}).then(result =>{
        var blob = new Blob([result.data],{type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
        Temp = document.createElement("a");
        Temp.href = window.URL.createObjectURL(blob);
        Temp.download =decodeURI(result.headers.map['content-disposition'][0].split("filename*=utf-8''")[1])
        $('body').append(Temp);
        Temp.click();
        Temp.remove()
      },
      (error) =>{
        console.log('error',error);
      })
    }

    handleSubmit() {
      this.deleteLoading = true
      let formData = new FormData();
      formData.append('device', this.file);
      this.$http.post('/api/device_batch_delete', formData, { emulateJSON: true })
        .then(
          res => {
          this.$message.success(res.body.data)
          this.showDeleteDialog = false
        },
        error =>{
          this.errText = error.body.msg ?? error.body.data.msg
        })

       this.deleteLoading = false
    }

    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)

      const { value: searchListView } = root.query({ as: <el-row /> }).next()

      searchListView?.children.push(
        <el-col span={5}>
          <el-button type="danger" size="small" style="float: right;" onClick={() => this.isShowDeleteDialog()}>批量删除</el-button>
        </el-col>
      )

      root.children.push(
        <el-dialog
          title='批量删除'
          visible={this.showDeleteDialog}
          size="tiny"
          top="20%"
          before-close={() => this.showDeleteDialog = false}
        >
          <input type="file" accept=".csv,.xls,.xlsx" id="file" name="file" onChange={(e) => this.getFile(e)} style="width:70px" />
          <span>{this.fileName}</span>
          <div style="margin-top: 14px;"><el-button type="text" onClick={() => this.downLoad()}>下载模板文件</el-button></div>
          <span slot="footer" class="dialog-footer">
           {
            this.errText &&
            <span class="tips-text"  style="padding-left: 0;"><i class="el-icon-circle-cross"></i>{this.errText}</span>
           }
            <el-button onClick={() => this.showDeleteDialog = false}>取 消</el-button>
            <el-button type="danger" onClick={() => this.handleSubmit()} v-loading={ this.deleteLoading }>删 除</el-button>
          </span>
        </el-dialog>
      )

      return vnode
    }
  }

  return DeviceNewView
}
