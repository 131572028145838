import { Component, Watch } from '~/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

export
function PackageEditViewProvider(SuperComponent) {
  @Component()
  class PackageEditView extends SuperComponent {
    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)

      if (this.dialog.detail) {
        const panelQuery = root.query({ as: <el-card class="box-card" />, parentAs: <el-form-item /> })

        for (const item of this.dialog.detail) {
          const { value: panelView } = panelQuery.next()

          if (!panelView) {
            continue
          }

          const { value: referenceView } = panelView.query({ as: <el-input /> }).next()

          referenceView?.after(
            <el-input value={ item.lie_time ?? 0 } onInput={ e => this.$set(item, 'lie_time', +e) }>
             <template slot="prepend">平躺时间</template>
             <template slot="append">分钟</template>
            </el-input>
          )
        }
      }

      return vnode
    }

  }


  return PackageEditView
}
