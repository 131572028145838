import { ReactiveComponent } from '~/commons/component/ReactiveComponent'
import { Component, Watch } from '~/commons/component/decorators'

import { ajax } from '~/services/ajax'

import { Chart } from '~/widgets/Chart'
import { default as defaultLineChartOptions } from '~/widgets/etc/chart/line.json'

@Component({
  components: {
    'sf-chart': Chart,
  }
})
export
class FreeChargeDataView extends ReactiveComponent {
  queryTime = [ '', '' ]
  queryAgent = ''
  querySupervisor = ''
  queryNetwork = ''

  agentOptions = []
  supervisorOptions = []
  networkOptions = []

  powerList = []

  modelData1 = {}
  modelData2 = {}
  tableData1 = []
  tableData2 = []

  mounted() {
    this.loadAgents()
    this.loadPowerList()
    this.loadChartAndTableData()
  }

  get title() {
    const item = this.powerList.find(item => +item.type === +this.$route.query.type)
    return item && item.name || '免费充电管理'
  }

  loadAgents() {
    this.getFilterOptions({ type: 1 }, data => this.agentOptions = data)
  }

  @Watch('queryAgent')
  loadSupervisors() {
    if (this.queryAgent) {
      this.getFilterOptions({ type: 2, id: this.queryAgent }, data => this.supervisorOptions = data)
    }
  }

  @Watch('querySupervisor')
  loadNetworks() {
    if (this.querySupervisor) {
      this.getFilterOptions({ type: 3, id: this.querySupervisor }, data => this.networkOptions = data)
    }
  }

  @Watch('queryAgent')
  @Watch('querySupervisor')
  @Watch('queryTime')
  loadChartAndTableData() {
    this.getChartAndTableData({ subscribed: 1 }, data => {
      this.tableData1 = data.list
      this.modelData1 = this.transformModelData(data)
    })

    this.getChartAndTableData({ subscribed: 2 }, data => {
      this.tableData2 = data.list
      this.modelData2 = this.transformModelData(data)
    })
  }

  loadPowerList() {
    ajax(
      '/api/power_list_index',
      {
        method: 'GET',
        // body: { type: this.$route.query.type, },
      },
      (error, data) => {
        if (error) {
          this.$message({ message: '获取数据失败', type: 'error' })
        } else {
          console.log('[features] power_list', data)
          this.powerList = data
        }
      }
    )
  }

  @Watch('queryAgent')
  resetQuerySupervisor() {
    this.querySupervisor = ''
  }

  @Watch('querySupervisor')
  resetQueryNetwork() {
    this.queryNetwork = ''
  }

  transformModelData(data) {
    const modelX = data.model_x
    const modelY1 = data.click_model
    const modelY2 = data.get_coupon_model
    const modelY3 = data.use_coupon_model

    return {
      extends: defaultLineChartOptions,

      title: {
        show: false,
      },

      xAxis: {
        type: 'category',
        data: modelX,
      },

      yAxis: {
        type: 'value',
        // nameRotate: 90,
      },

      series: [
        {
          name: '点击免费充电人数',
          data: modelY1,
          type: 'line',
        },
        {
          name: '领取优惠券人数',
          data: modelY2,
          type: 'line',
          // yAxisIndex: 1,
        },
        {
          name: '使用优惠券人数',
          data: modelY3,
          type: 'line',
          // yAxisIndex: 2,
        },
      ]
    }
  }

  getFilterOptions(config, callback) {
    const { type, id } = config

    ajax(
      '/api/get_search_condition',
      {
        method: 'POST',
        body: { type, id },
      },
      (error, data) => {
        if (error) {
          this.$message({ message: error.message || error, type: 'error' })
        } else {
          callback(data)
        }
      }
    )
  }

  exportChartAndTableData(subscribed) {
    this.getChartAndTableData({ subscribed, isExport: true }, url => {
      const anchor = document.createElement('a')
      anchor.download = ''
      anchor.href = url
      document.body.append(anchor)
      anchor.click()
      anchor.remove()
    })
  }

  getChartAndTableData(config, callback) {
    const { subscribed, isExport } = config

    ajax(
      '/api/get_data_map',
      {
        method: 'POST',
        body: {
          type: this.$route.query.type,
          agent_id: this.queryAgent,
          supervisor_id: this.querySupervisor,
          network_id: this.queryNetwork,
          b_time: this.queryTime[0],
          e_time: this.queryTime[1],
          subscribe: subscribed,
          is_export: isExport,
        }
      },
      (error, data) => {
        if (error) {
          this.$message({ message: error.message || error, type: 'error' })
        } else {
          callback(data)
        }
      }
    )
  }

  render() {
    return (
      <div class="freecharge-data-main">
        <el-card style="margin-bottom: 48px;">
          <div slot="header">{ this.title }</div>

          <el-row gutter={ 32 }>
            <el-col span={ 4 }>
              <el-select label="代理商" placeholder="请选择代理商" value={ this.queryAgent } onInput={ value => this.queryAgent = +value || '' } clearable>
                {
                  this.agentOptions.map((item, i) => <el-option value={ item.id } label={ item.name } key={ i }></el-option>)
                }
              </el-select>
            </el-col>
            <el-col span={ 4 }>
              <el-select label="经销点" placeholder="请选择经销点" value={ this.querySupervisor } onInput={ value => this.querySupervisor = +value || '' } clearable>
                {
                  this.supervisorOptions.map((item, i) => <el-option value={ item.id } label={ item.name } key={ i }></el-option>)
                }
              </el-select>
            </el-col>
            <el-col span={ 4 }>
              <el-select label="网点" placeholder="请选择网点" value={ this.queryNetwork } onInput={ value => this.queryNetwork = +value || ''  } clearable>
                {
                  this.networkOptions.map((item, i) => <el-option value={ item.id } label={ item.name } key={ i }></el-option>)
                }
              </el-select>
            </el-col>
            <el-col span={ 8 }>
              <el-date-picker label="时间" placeholder="请选择时间区间" type="datetimerange" value={ this.queryTime } onInput={ time => this.queryTime = time.map(date => date && date.toLocaleDateString('sv-SE') || '') } format="yyyy-MM-dd HH:mm:ss" />
            </el-col>
          </el-row>
        </el-card>

        <el-card style="margin-bottom: 48px;">
          <el-row slot="header">
            <el-col span={ 4 }>
              <span class="label">未关注用户</span>
            </el-col>
            <el-col span={ 4 } offset={ 24 - 4 - 4 } style="text-align: right;">
              <el-button type="primary" onClick={ () => this.exportChartAndTableData(1) }>导出数据</el-button>
            </el-col>
          </el-row>

          <div style="width: 100%; height: 400px; margin-bottom: 48px;">
            <sf-chart data={ this.modelData1 } />
          </div>

          <el-table stripe data={ this.tableData1 } ref="table1" style="margin-bottom: 24px;">
            <el-table-column label="时间 (近7天)" prop="day_time" />
            <el-table-column label="点击免费充电">
              <el-table-column label="点击次数" prop="click_total" />
              <el-table-column label="点击人数" prop="click_user" />
            </el-table-column>
            <el-table-column label="半浮框 (公众号)">
              <el-table-column label="展示次数" prop="float_gz_show_total" />
              <el-table-column label="展示时长" prop="float_gz_show_time" />
            </el-table-column>
            <el-table-column label="充电说明页">
              <el-table-column label="pv" prop="power_page_pv" />
              <el-table-column label="uv" prop="power_page_uv" />
            </el-table-column>
            <el-table-column label="充电页下单按钮" prop="power_page_order" />
            <el-table-column label="领券数 (公众号)" prop="get_coupon" />
            <el-table-column label="用券数 (公众号)" prop="use_coupon" />

          </el-table>

        </el-card>

        <el-card style="margin-bottom: 48px;">
          <el-row slot="header">
            <el-col span={ 4 }>
              <span class="label">已关注用户</span>
            </el-col>
            <el-col span={ 4 } offset={ 24 - 4 - 4 } style="text-align: right;">
              <el-button type="primary" onClick={ () => this.exportChartAndTableData(2) }>导出数据</el-button>
            </el-col>
          </el-row>

          <div style="width: 100%; height: 400px; margin-bottom: 48px;">
            <sf-chart data={ this.modelData2 } />
          </div>

          <el-table stripe data={ this.tableData2 } ref="table2" style="margin-bottom: 24px;">
            <el-table-column label="时间 (近7天)" prop="day_time" />
            <el-table-column label="点击免费充电">
              <el-table-column label="点击次数" prop="click_total" />
              <el-table-column label="点击人数" prop="click_user" />
            </el-table-column>
            <el-table-column label="半浮框 (使用说明)">
              <el-table-column label="展示次数" prop="float_page_show_total" />
              <el-table-column label="展示时长" prop="float_page_show_time" />
            </el-table-column>
            <el-table-column label="领券数 (公众号)" prop="get_coupon" />
            <el-table-column label="用券数 (公众号)" prop="use_coupon" />

          </el-table>
        </el-card>

      </div>
    )
  }
}
