import { getAppRoot } from '@rc/vue-facade'

export
const root = await (() => {
  const rootThunk = getAppRoot()

  return {
    then(onFullfill, onReject) {
      rootThunk((err, root) => {
        if (err) {
          onReject(err)
        } else {
          onFullfill(root)
        }
      })
    }
  }
})()
