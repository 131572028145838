import { Component, Watch } from '~/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { ajax } from '~/services/ajax'

export
function CouponOrderListViewProvider(SuperComponent) {
  @Component()
  class CouponOrderListView extends SuperComponent {
    shouldShowDownloadTasks = false

    downloadTaskData = []

    /** @override */
    handleExport() {
      ajax(
        '/api/export_coupon_task',
        {
          method: 'POST',
          body: {
            id: this.$route.query.id,
          },
        },
        (error, data) => {
          if (error) {
            this.$message({ message: '获取数据失败', type: 'error' })
          } else {
            this.$message({ message: '任务已在后台执行，请到下载列表下载', type: 'success' })
          }
        }
      )
    }

    showDownloadTasks() {
      this.shouldShowDownloadTasks = true
      this.loadDownloadTasks()
    }

    loadDownloadTasks() {
      ajax(
        '/api/download_list',
        {
          method: 'POST',
        },
        (error, data) => {
          if (error) {
            this.$message({ message: '获取数据失败', type: 'error' })
          } else {
            this.downloadTaskData = data.data
          }
        }
      )
    }

    download(url) {
      const anchor = document.createElement('a')
      anchor.download = ''
      anchor.href = url
      document.body.append(anchor)
      anchor.click()
      anchor.remove()
    }

    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)

      const colQuery = root.query({ tagName: 'el-col' })

      // skip
      colQuery.next()

      const { value: colView } = colQuery.next()

      if (colView) {
        colView.children.push(
          <el-button type="primary" onClick={ () => this.showDownloadTasks() }>下载列表</el-button>
        )
      }

      root.children.push(
        <el-dialog title="下载列表" visible={ this.shouldShowDownloadTasks } on={ { 'update:visible': v => (this.shouldShowDownloadTasks = v) } } style="z-index: 100000; ">
          <el-table data={ this.downloadTaskData }>
            <el-table-column label="类型" property="title" />
            <el-table-column label="申请时间" property="gmt_create" />
            <el-table-column label="状态" scopedSlots={
              {
                default: scope => (
                  scope.row.status === 0 ? <span>未完成<br/>{ this.download_time }</span> :
                  scope.row.status === 1 ? <span>已完成</span> : null
                )
              }
            } />
            <el-table-column label="操作" scopedSlots={
              {
                default: scope => (
                  <el-button type="primary" size="small" disabled={ !scope.row.zip_url } onClick={ () => this.download(scope.row.zip_url) }>下载</el-button>
                )
              }
            } />
          </el-table>

          <div slot="footer" style="height: 20px; "/>
        </el-dialog>
      )

      return vnode
    }

  }


  return CouponOrderListView
}
