import { ReactiveComponent } from '~/commons/component/ReactiveComponent'
import { Component, Watch } from '~/commons/component/decorators'

import { ajax } from '~/services/ajax'

@Component()
export
  class ComposePackageExchangeView extends ReactiveComponent {
  keyword = '' // 输入兑换码
  debounceTimer = null //防抖定时器

  loading = false // 加载效果

  queryPageIndex = 1 //分页页码
  total // 总条数

  isShowVoucherDialog = false //兑换码弹窗
  disabledBtn = false //生成按钮是否禁用
  batch_size = null //生成兑换码数量
  codes = null //生成的兑换码

  exportLoading = false //导出按钮加载效果

  packageComposeVoucherData = []


  created() {
    this.getPackageComposeVoucher()
  }

  @Watch('queryPageIndex')
  @Watch('keyword')
  loadPackageComposeVoucher() {
    this.getPackageComposeVoucher()
  }

  // 输入框 - 节流
  handleInput(e){
    clearTimeout(this.debounceTimer)

    this.debounceTimer = setTimeout(() => {
      this.keyword = e
    },500)
  }

  // 复制链接
  async copyLink(){
    await navigator.clipboard.writeText('https://' + document.domain + '/home/couponConversion?compose_code=true')

    this.$message({message:'复制成功',type:'success'})
  }

  getPackageComposeVoucher() {
    this.loading = true
    ajax(`/api/packageComposeVoucher/index?page=${this.queryPageIndex}&package_compose_id=${this.$router.history.current.query.id}&keyword=${this.keyword}`,
      {
        method: 'GET',
      },

      (error, data) => {
        if (error) {
          this.$message({ message: '获取数据失败', type: 'error' })
        } else {
          this.packageComposeVoucherData = data.list.data
          this.total = data.list.total
          this.loading = false
        }
      })
  }


  // 兑换码导出 逻辑
  VoucherExport(){
    this.exportLoading = true

    ajax('/api/packageComposeVoucher/export',
    {
      method:'POST',
      body : {
      package_compose_id : this.$router.currentRoute.query.id
      }
    },

    (error,data) => {
      if (error) {
        this.$message({ message: error.message ?? '操作失败', type: 'error' })
      } else {
        window.location.href = data.csv_url
        this.exportLoading = false
      }
    }
    )
  }


  // 弹出兑换码弹窗 逻辑
  showVoucherDialog(){
    // 弹窗内容清空
    this.batch_size = null
    this.codes = null

    this.disabledBtn = false
    this.isShowVoucherDialog = true
  }

  // 生成兑换码 逻辑
  creatVoucher(){
    ajax('/api/packageComposeVoucher/create',
    {
      method:'POST',
      body:{
        batch_size:this.batch_size,
        package_compose_id:this.$router.currentRoute.query.id
      }
    },

    (error,data) => {
      if (error) {
        this.$message({ message: error.message ?? '操作失败', type: 'error' })
      } else {
        this.codes = data.codes
        this.disabledBtn = true
      }
    })
  }

  // 兑换码弹窗点击确定 逻辑
  handleSubmitVoucherDialog(){
     if(this.codes){
      ajax('/api/packageComposeVoucher/save',
      {
        method: 'POST',
        body: {
          package_compose_id:this.$router.currentRoute.query.id,
          codes : this.codes
        }
      },

      (error,data) => {
        if (error) {
          this.$message({ message: error.message ?? '操作失败', type: 'error' })
        } else {
          this.$message({type: 'success',message: '生成成功'});
          this.getPackageComposeVoucher()
          this.isShowVoucherDialog = false
        }
      })
     }else {
      this.$message({type: 'error',message: '请先生成兑换码'});
     }
    }


  // 关闭兑换码弹窗 逻辑
  closeVoucherDialog() {
    // 弹窗数据清空
    this.batch_size = null
    this.codes = null

    this.isShowVoucherDialog = false
  }



  render() {
    return (
      <section class='package-exchange'>
        {/* search-box */}
        <section style="margin-top: 20px;">
          <el-row gutter={24}>
            <el-col span={6}>
              <el-input placeholder="输入兑换码" value={this.keyword} onInput={e => this.handleInput(e) } suffix-icon="el-icon-search" clearable />
            </el-col>

            <el-col span={18} style="text-align: right;">
              <el-button type="primary" onClick={ () => this.copyLink() }>复制兑换码链接</el-button>
              <el-button type="primary" onClick={ () => this.VoucherExport() } v-loading={this.exportLoading}>批量导出</el-button>
              <el-button type="primary" onClick={() => this.showVoucherDialog()}>生成兑换码</el-button>
            </el-col>
          </el-row>
        </section>

        {/* content */}
        <section style="margin-top: 20px;">
          <el-table data={this.packageComposeVoucherData} border style="width: 100%" v-loading={this.loading}>
            <el-table-column prop="code" label="兑换码" />
            <el-table-column prop="gmt_create" label="创建时间" />
            <el-table-column prop="user_id" label="兑换用户id" />
            <el-table-column prop="exchange_time" label="兑换时间" />
          </el-table>
        </section>

        {/* pagination */}
        <section style="margin-top: 20px; text-align: center; ">
          <el-pagination currentPage={this.queryPageIndex} pageSize={10} layout="prev, pager, next" on-current-change={e => (this.queryPageIndex = e)} total={this.total} />
        </section>

        <el-dialog
          title='生成兑换码'
          visible={this.isShowVoucherDialog}
          before-close={() => this.closeVoucherDialog()}
        >
          <el-form label-width='70px'>
            <el-row>
              <el-col span={ 20 }>
                <el-form-item>
                  <el-card>
                    <el-form-item label='生成数量'>
                      <el-row >
                        <el-col span={20}>
                          <el-input placeholder="输入生成数量" value={this.batch_size} onInput={e => (this.batch_size = e)} />
                        </el-col>
                        <el-col span={4} style="text-align: end;">
                          <el-button type="primary" onClick={() => this.creatVoucher() } disabled={this.disabledBtn}>生成</el-button>
                        </el-col>
                      </el-row>
                    </el-form-item>

                    <el-form-item label='兑换码' style="margin-top: 20px;">
                      <el-input type="textarea" value={this.codes} onInput={(e) => this.codes = e} readonly rows={10} />
                    </el-form-item>
                  </el-card>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>

          <span slot="footer" class="dialog-footer">
            <el-button onClick={ () => this.closeVoucherDialog() }>取 消</el-button>
            <el-button type="primary" onClick={ () => this.handleSubmitVoucherDialog() }>确 定</el-button>
          </span>
        </el-dialog>
      </section>
    )
  }
}
